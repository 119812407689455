.multiple-artworks {
  display: flex;
  flex-wrap: wrap;

  .select-artwork {
    margin: 1rem;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 150px;
      height: 150px;
    }
    :hover {
      opacity: 0.5;
    }
  }
}
.multiple-artwork-footer {
  display: flex;
  justify-content: center;
}
