.sub-header {
  position: relative;
  margin-bottom: 1rem;

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -3px;
  }
  .sub-header-title {
    text-align: center;
    padding-left: 2rem;

    h6:first-of-type {
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 767px) {
  // .sub-header-title {
  //   h6:first-of-type {
  //     padding-left: 2rem;
  //   }
  // }
  .back-btn {
    top: -8px !important;
  }
}
