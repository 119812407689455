.chat-list-item {
  margin: 1rem;
  .rce-container-citem {
    .rce-citem {
      .rce-citem-avatar {
        .rce-avatar-container.circle {
          border-radius: 15px !important;
          height: 60px;
          width: 60px;
        }
        .rce-avatar-container {
          img {
            border-radius: 15px !important;
          }
        }
      }
      .rce-citem-body {
        .rce-citem-body--top {
          .rce-citem-body--top-time {
            color: #333 !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
