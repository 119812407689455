.filter-badge-wrapper {
  display: flex;

  .filter-badge-item {
    padding: 0.3rem;
    margin: 0 1rem 0 0;
    background-color: $primary_btn-bg-color !important;
    color: $primary_btn-text-color !important;

    * {
      line-height: 1.1;
    }

    .filter-badge-content {
      padding: 0 !important;

      .filter-badge-header {
        display: flex;
        justify-content: space-between;

        .filter-badge-header-value {
          // line-height: 1.;
          font-size: 16px !important;
          font-weight: bold;
        }

        .filter-badge-close-btn {
          color: $primary_btn-text-color;
          padding: 0;
          margin-left: 0.5rem;

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .filter-badge-body {
        text-align: center;

        * {
          font-size: 10px !important;
          // line-height: 1.1;
        }
      }
    }
  }
}
