.balance-item {
  border: 1px solid $primary_btn-bg-color;
  border-radius: 12px !important;
  margin-bottom: 1rem;
}
.balance-item-content {
  text-align: center;
}

.balance-item-actions {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.balance-item-action {
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: $primary_btn-bg-color;
  color: white;
  margin: 5px 0px 0px !important;
}
.balance-item-action:hover {
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  color: white;
}
