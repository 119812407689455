.image-chooser-body {
  height: 350px;
  background-color: #f8f8f8;
  width: 100%;
  position: relative;

  .image-chooser-add-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .selected-image-copyied {
      color: red;
    }
    button {
      color: black;
    }
  }
  .image-choose-datestep {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .select-image {
    visibility: hidden;
  }
  img {
    object-fit: cover;
    max-height: 320px;
  }
  .image-choose-datestep {
    color: red;
  }
}
