.nft-item-card {
  box-shadow: none !important;
  .nft-item-card-content {
    padding: 0.5rem 0;

    .nft-title {
      font-size: 21px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-transform: uppercase;

      .fav-icon {
        width: 28px;
      }
    }
    .nft-info {
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 0.5rem;

      .nft-info-block {
        &:first-child {
          text-align: left;
        }
        flex: 1;
        // display: flex;
        // flex-direction: column;
        margin: 0 5px 0 0;
        text-align: right;
        text-transform: uppercase;
        color: #000;
        span {
          font-size: 13px;
        }
        h6 {
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
    .nft-hot-btn {
      margin-top: 0.5rem;
    }
    .nft-description {
      margin-top: 1rem;
      height: 8.5rem;
      color: #000;
      white-space: pre-wrap; /* or pre-line */
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
  }
  .nft-item-card-actions {
    display: block;
    .arrow-icon {
      width: 40px;
      cursor: pointer;
    }
  }
}

.nft-item-animated-parent {
  height: 270px;
  overflow: hidden;
  .animate-nft-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    height: 370px;
    position: relative;
  }
  .animated-img-d1 {
    @keyframes MoveUpDownd1 {
      0%,
      100% {
        bottom: 0;
      }
      50% {
        bottom: 100px;
      }
    }
  }
  .animated-img-d2 {
    @keyframes MoveUpDownd2 {
      0%,
      100% {
        bottom: 100px;
      }
      50% {
        bottom: 0;
      }
    }
  }
}
