.nft-meida-wrapper {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: auto;
  border-radius: 4px;
  .nft-media-content {
    padding: 0.25rem 1rem;
  }
}
@media screen and (min-width: 769px) {
  .nft-meida-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.media-image {
  width: 100%;
  max-height: 95vh;
}
.media-video {
  width: 100%;
  max-height: 70vh;
}
.media-audio {
  width: 100%;
  max-height: 70vh;
}
.nft-media-close-btn {
  display: flex;
  justify-content: flex-end;
}
