.lang-selector-grid {
  .dropdown {
    .dropdown-toggle::after {
      color: $black-color;
    }
    .btn-link:hover {
      background-color: $secondary-color;
      color: $secondary-color;
      text-decoration: none;
    }
    .btn-link:focus {
      background-color: $secondary-color;
      color: $secondary-color;
    }
    .btn {
      padding: 0;
    }
    .dropdown-menu {
      margin: 0;
      padding: 0;
      min-width: 7rem;
      .dropdown-item {
        padding: 0.5rem;
      }
    }
  }
}
