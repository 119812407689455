// Bootstrap brand color customization
:root {
  --primary-btn-bg-color: #000000;
  --primary-btn-text-color: #ffffff;

  --secondary-btn-bg-color: #ffffff;
  --secondary-btn-text-color: #000000;
  --secondary_btn_border_color: #000000;

  --tertiary-btn-bg-color: #f0f0f0;
  --tertiary_btn_text_color: #000000;

  --danger-btn-bg-color: #e52613;
  --danger_btn_text_color: #ffffff;
  --danger_btn_border_color: #e52613;
}

$primary_btn-bg-color: var(--primary-btn-bg-color);
$primary_btn-text-color: var(--primary-btn-text-color);

$seconday_btn-bg-color: var(--secondary-btn-bg-color);
$secondary_btn-text-color: var(--secondary-btn-text-color);
$secondary_btn-border-color: var(--secondary_btn_border_color);

$tertiary_btn-bg-color: var(--tertiary-btn-bg-color);
$tertiary_btn-text-color: var(--tertiary_btn_text_color);

$danger_btn-bg-color: var(--danger-btn-bg-color);
$danger_btn-text-color: var(--danger_btn_text_color);
$danger_btn-border-color: var(--danger_btn_border_color);

$white-color: #ffffff !default;
$gray-color: #999999 !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;
$black-color: #000 !default;
$secondary-color: #f5f5f5 !default;
$btn-border-color: #1c1c1c;
