.selected-item {
  text-align: center;
}

.controls-container {
  width: 100%;
  --highlight-width: auto + 20px;
  --highlight-x-pos: 0+ 20pz;

  display: flex;
  margin: 20px 0 25px;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: #e2e2e2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // width: 70%;
  // padding: 12px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  width: 100%;
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;

  label {
    cursor: pointer;
    display: block;
    font-weight: normal;
    padding: 10px;
    transition: color 0.5s ease;
  }
}

.segment.active label {
  color: #000;
}

.controls::before {
  content: '';
  background: white;
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}
