.tags-section {
  .tags-grid {
    .tag-item {
      margin-left: auto;
      margin-right: auto;
      .tag-btn {
        border: 1px solid $secondary_btn-border-color !important;
        font-size: 1rem;
        font-weight: bold;
        text-transform: none;
      }
      .tag-non-selected-btn {
        background-color: $seconday_btn-bg-color;
        color: $secondary_btn-text-color;
      }
      .tag-selected-btn {
        background-color: $primary_btn-bg-color !important;
        color: $primary_btn-text-color !important;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .tags-section {
    height: 150px;
    overflow: auto;
  }
}

.sorting-filter {
  margin-left: 8px;
}
.filters-imgs {
  cursor: pointer;
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 30px;
}
