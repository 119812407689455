main.form-container {
  form {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  main.form-container {
    max-width: 850px !important;
  }
}
