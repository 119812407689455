.nft-thumbnial-container {
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;
  .nft-thumbnail {
    max-width: 50%;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      opacity: 0.5;
    }
  }
}

.nft-artworks {
  margin-top: 1rem;
}

.artwork-thumbnail-img {
  height: 100px;
  width: 100px;
  margin-right: 1.5rem;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}
