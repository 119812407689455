.nft-detail-tabs {
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
  button {
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }
}
