.bidding-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding: 0 0 0.5rem;
}
.bidding-form-footer button {
  font-weight: bold !important;
  margin-left: 1rem;
  color: $primary_btn-bg-color;
  border: 1px solid $primary_btn-bg-color !important;
  padding: 5px 25px;
}
.bidding-form-footer button:hover {
  color: $primary_btn-text-color !important;
  background: $primary_btn-bg-color !important;
}

.payable-amount {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 700;

  label {
    min-width: 20%;
  }
}

@media screen and (max-width: 767px) {
  .bidding-form-footer {
    flex-direction: column-reverse;
    button {
      margin: 0.5rem 0 !important;
    }
  }
}
