.private-policy {
    word-wrap: break-word;
  }
  .private-policy a {
    color: black;
    text-decoration: none;
  }
  
  .private-policy a:hover {
    opacity: 0.5;
  }
  