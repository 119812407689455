.nft-card-modal-wrapper {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 24;
  overflow: auto;
  margin: 2rem 4rem;
  border-radius: 4px;
  .modal-close-btn {
    display: flex;
    justify-content: flex-end;
  }
  .nft-card-modal {
    padding: 1rem 2rem !important;
    .nft-card-modal-content {
      .nft-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-transform: uppercase;
        .fav-icon {
          width: 28px;
        }
      }
      .nft-info {
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin-top: 0.5rem;

        .nft-info-block {
          &:first-child {
            text-align: left;
          }
          // &:nth-child(2) {
          //   text-align: center;
          // }
          flex: 1;
          // display: flex;
          // flex-direction: column;
          margin: 0 5px 0 0;
          text-align: right;
          text-transform: uppercase;
          color: #000;

          span {
            font-size: 13.5px;
          }
          h5 {
            font-size: 19px;
            font-weight: bold;
          }
        }
      }
      .nft-hot-btn {
        // display: flex;
        // justify-content: space-between;

        .rm-bid-btn-wrapper {
          margin-top: 12px;
          margin-bottom: 12px;

          .remove-bid-btn {
            border: 1px solid #000;
            color: red;
          }
        }
      }
      .nft-address {
        margin-top: 0.5rem;
        span {
          font-size: 13.5px;
        }
        a {
          color: #000;
          text-decoration: none;
          cursor: pointer;
          word-break: break-word;
          font-size: 19px;
          font-weight: bold;
        }
      }
      .nft-address a:hover {
        opacity: 0.7;
      }
      .nft-description {
        margin-top: 0.5rem;
        color: #000;
        white-space: pre-wrap; /* or pre-line */
      }
    }
  }
}

.chunks-container {
  label {
    font-weight: 700;
  }
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    .accordion-item-title {
      font-weight: bold;
    }
    .accordion-item-token {
      padding-right: 0.5rem;
    }
    .accordion-item-detail {
      background-color: red;
      form .place-nft-chunk {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nft-card-modal-wrapper {
    margin: 1rem 0.5rem;
  }
  .nft-hot-btn {
    flex-direction: column;
    .rm-bid-btn-wrapper {
      button:nth-of-type(2) {
        width: 100%;
      }
    }
  }
}
