.artwork-detail-header {
  display: flex;
  justify-content: space-between;

  .artwork-request-button {
    background-color: $primary_btn-bg-color !important;
    color: $primary_btn-text-color !important;
    min-width: 176px;
    padding: 0.25rem 0.5rem;
    font-weight: bold;
  }
}

.artwork-detial-back-btn {
  display: flex;
  justify-content: flex-start;
  .artwork-name {
    margin-left: 0.5rem;
    font-weight: bold;
  }
}
.artwork-detail-tabs {
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
  button {
    color: #000;
    font-weight: bold;
    font-size: 16px;
  }
}
