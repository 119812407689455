.track-wrapper {
  color: #000;
  word-wrap: break-word;

  .track-date {
    font-weight: bold;
    div:first-of-type {
      font-size: 32px;
    }
  }
  .track-content {
    .track-link a {
      color: #000;
      text-decoration-color: #000;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
