.menu-drawer-btn {
  padding: 0 0 0 12px;
}
.menu-burger-icon {
  width: 24px;
  &:hover {
    cursor: pointer;
  }
}
.drawer-wrapper {
  width: 330px;
  // height: 100vh;
  position: relative;

  .drawer-header {
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    align-items: center;

    .main-logo-drawer {
      // width: 100px;
      // min-width: 85px;
      min-height: 85px;
      max-height: 85px;
    }
    .close-btn {
      // margin-top: 2rem;
      height: 3.5rem;
    }
  }

  hr {
    margin: 0 2rem !important;
  }

  .drawer-item span {
    font-weight: bold;
  }
  .drawer-item-img {
    background-color: $primary_btn-bg-color;
    // border-radius: 4px;
  }

  ul.menu-items li:last-child {
    margin-bottom: 75px;
  }

  .notifiction-count-badge {
    background-color: $primary_btn-bg-color;
    color: $primary_btn-text-color !important;
    border-radius: 100%;
    padding: 0.25rem 0.5rem;
    font-size: smaller;
    margin-right: 3rem;
  }
  .drawer-footer {
    position: fixed;
    bottom: 0;
    padding-bottom: 0.5rem;
    background-color: #fff;
    z-index: 100;
    width: 330px;
  }
}
