.modal-styles {
  .modal-header {
    border-bottom: 0;
    padding: 1rem 1rem 0 !important;
    display: flex;
    justify-content: center;
    .modal-close-icon {
      position: absolute;
      right: 0;
      top: 0;
      button {
        color: #000;
      }
    }
    .filter-modal-title h5 {
      font-weight: bold;
    }
    .sorting-modal-title h5 {
      font-weight: bold;
    }
  }
  .modal-sub-header {
    text-align: center;
    padding: 0 1rem;
  }
  .modal-body {
    padding: 1rem 2rem;
  }
}

.modal-styles:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
