.nft-block {
  padding: 0.5rem;
  word-break: break-word;
  color: #000;
  border-radius: 5px;
  .nft-block-title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    .nft-title {
      display: flex;
      div {
        font-weight: bold;
      }
      .nft-deeplink {
        height: 20px;
        width: 20px;
        margin: -5px 0 0;
      }
    }
    .nft-tokens {
      span:first-of-type {
        color: #d6d4d2;
        font-size: x-large;
      }
    }
  }
  .nft-blockchain {
    background: black;
    border-radius: 5px;
    color: #fff;
    align-self: center;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    width: fit-content;
  }
  .nft-block-body {
    display: flex;
    // justify-content: flex-end;
    text-align: right;

    // div:first-child {
    //   flex: 2;
    // }

    div:nth-child(2) {
      flex: 1;
    }

    .nft-price {
      display: flex;
      flex-direction: column;
      &:nth-child(2) {
        margin-left: 1rem;
      }
      span {
        text-transform: uppercase;
        font-size: 12.5px;
      }
    }
  }
  .nft-block-footer {
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    div span {
      text-transform: uppercase;
      font-size: 12.5px;
    }
    .artist-block {
      display: flex;
      flex-direction: column;
    }
    .year-block {
      display: flex;
      flex-direction: column;
    }
  }
  .address-block {
    margin-top: 0.5rem;
    span.sub-headings {
      margin-bottom: 0.5rem;
      font-size: 12.5px;
    }
  }
}
