.filters-section {
  margin: 0.5rem 0;
  .filters-grid {
    .filter-item {
      .filter-btn {
        border: 1px solid $secondary_btn-border-color !important;
        font-size: 1rem;
        font-weight: bold;
      }
      .tag-non-selected-btn {
        background-color: $seconday_btn-bg-color;
        color: $secondary_btn-text-color;
      }
    }
    .filter-badge-grid {
      display: -webkit-box;
      overflow: auto;
    }
  }

  form button[type='submit'] {
    padding-right: 15%;
    padding-left: 15%;
  }
}
