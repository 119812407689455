.bidding-modal {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: auto;
  border-radius: 4px;
  width: 90vw;
  padding: 0.5rem 1rem;
  margin: auto;
}

@media screen and (min-width: 769px) {
  .bidding-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
  }
}
