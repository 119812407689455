.main-wrapper {
  width: 100%;
}

.nft-image-selection-thumbnail {
  position: relative;
  padding: 2rem;
}

.external-image-selection {
  position: absolute;
  right: 0;
  bottom: 2rem;
  cursor: pointer;
}
.nft-desc-label {
  display: flex;
  justify-content: space-between;
}
.push-nft-img {
  max-width: 100%;
  max-height: 600px;
}
.push-banner-img {
  max-width: 100%;
  padding: 0.5rem;
}

.banner-img-continute-btn {
  width: 75%;
}
