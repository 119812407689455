.accordion-wrapper {
  width: 100%;
  .list-item {
    span {
      font-weight: bold;
    }
    p {
      color: #000;
    }
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

button.service-button {
  color: $primary_btn-bg-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
