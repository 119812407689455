.text-wrap {
  word-break: break-word;
}

.currency-type {
  font-size: large;
  margin-left: 0.5rem;
}

.copy-key {
  color: #000 !important;
}
.balance-amount {
  font-size: xx-large;
}
