.artwork-overview-wrapper {
  .artwork-detail {
    color: #000;
    margin-top: 1rem;
    word-wrap: break-word;
    .artwork-info {
      display: flex;
      justify-content: space-between;
      h6 {
        font-weight: bold;
      }
    }
  }
}
@media screen and (min-width: 991px) {
  .artwork-detail {
    margin: 1rem 17%;
  }
}

.nft-description-wrapper {
  .nft-description {
    .nft-title {
      font-weight: bold;
    }
    .nft-info {
      word-break: break-word;
    }
  }
}

@media screen and (min-width: 991px) {
  .nft-description-wrapper {
    margin: 1rem 17%;
  }
}
