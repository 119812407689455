.provenance-wrapper {
  color: #000;
  word-wrap: break-word;
  .provenance-date {
    font-weight: bold;
    div:first-of-type {
      font-size: 32px;
    }
  }
  .provenance-content {
  }
}
