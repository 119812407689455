@import 'mrkt-components-styles/variables.scss';
@import 'mrkt-components-styles/nonAuthPages.scss';
@import 'mrkt-components-styles/languageSelector.scss';
@import 'mrkt-components-styles/home-page.scss';
@import 'mrkt-components-styles/header.scss';
@import 'mrkt-components-styles/subHeader.scss';
@import 'mrkt-components-styles/bootstrapModal.scss';
@import 'mrkt-components-styles/keysExchange.scss';
@import 'mrkt-components-styles/accordionItem.scss';
@import 'mrkt-components-styles/alertBox.scss';
@import 'mrkt-components-styles/balanceItem.scss';
@import 'mrkt-components-styles/creditsBalance.scss';
@import 'mrkt-components-styles/drawer.scss';
@import 'mrkt-components-styles/listItems.scss';
@import 'mrkt-components-styles/legal.scss';
@import 'mrkt-components-styles/login.scss';
@import 'mrkt-components-styles/nftItemCard.scss';
@import 'mrkt-components-styles/nftItemModal.scss';
@import 'mrkt-components-styles/documents.scss';
@import 'mrkt-components-styles/pdffGenerate.scss';
@import 'mrkt-components-styles/privatepolicy.scss';
@import 'mrkt-components-styles/segmentControl.scss';
@import 'mrkt-components-styles/termsAndConditions.scss';
@import 'mrkt-components-styles/walletCreation.scss';
@import 'mrkt-components-styles/walletKeyCopyWrapper.scss';
@import 'mrkt-components-styles/nftBlockCard.scss';
@import 'mrkt-components-styles/multipleArtworkPopup.scss';
@import 'mrkt-components-styles/support.scss';
@import 'mrkt-components-styles/filterBadge.scss';
@import 'mrkt-components-styles/artworkOverview.scss';
@import 'mrkt-components-styles/registerForm.scss';
@import 'mrkt-components-styles/biddingItem.scss';
@import 'mrkt-components-styles/nftOverview.scss';
@import 'mrkt-components-styles/nftMediaPopup.scss';
@import 'mrkt-components-styles/nftItemModalDetail.scss';
@import 'mrkt-components-styles/artworkModalDetail.scss';
@import 'mrkt-components-styles/emptyPlaceHolder.scss';
@import 'mrkt-components-styles/chat.scss';
@import 'mrkt-components-styles/imageChoose.scss';
@import 'mrkt-components-styles/pushNftToMarketplace.scss';
@import 'mrkt-components-styles/notificationItem.scss';
@import 'mrkt-components-styles/tags.scss';
@import 'mrkt-components-styles/filters.scss';
@import 'mrkt-components-styles/chatList.scss';
@import 'mrkt-components-styles/biddingModal.scss';
@import 'mrkt-components-styles/biddingForm.scss';
@import 'mrkt-components-styles/track.scss';
@import 'mrkt-components-styles/provenance.scss';
@import 'mrkt-components-styles/confirmBox.scss';
@import 'mrkt-components-styles/loader.scss';
@import 'mrkt-components-styles/genericModal.scss';
@import 'mrkt-components-styles/form.scss';

@font-face {
  font-family: montserrat-regular;
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: montserrat-semibold;
  src: url(../fonts/Montserrat-SemiBold.ttf);
  // font-weight: bold;
}

* {
  font-family: montserrat-regular !important;
}

body {
  color: $black-color;
}

.cursor-pointer {
  cursor: pointer;
}

.display-none {
  display: none;
}

.uppercase {
  text-transform: uppercase;
  color: $black-color;
}

.mt-16 {
  margin-top: 16px;
}
.mt-25 {
  margin-top: 25px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-reverse-5 {
  margin-left: -5 !important;
}
.mr-reverse-45 {
  margin-right: -45 !important;
}
.p-0 {
  padding: 0;
}
.p-t-0 {
  padding-top: 0 !important;
}
.m-0 {
  margin: 0;
}
.left100-percent {
  left: 100%;
}
/* primary btn style */

.primary-button {
  font-size: 1rem !important;
  line-height: 2.25 !important;
  background-color: $primary_btn-bg-color !important;
  color: $primary_btn-text-color !important;
}

.primary-button:hover {
  opacity: 0.85 !important;
}

.primary-button:disabled,
.primary-button[disabled] {
  background-color: $primary_btn-bg-color !important;
  color: $primary_btn-text-color !important;
  opacity: 0.5;
}

/* secondary btn style */

.secondary-button {
  font-size: 1rem !important;
  line-height: 1.75 !important;
  background-color: $seconday_btn-bg-color !important;
  color: $secondary_btn-text-color !important;
  border: 1px solid $secondary_btn-border-color !important;
}
.secondary-button:hover {
  opacity: 0.85 !important;
}

/* tertiary btn style  */

.tertiary-button {
  font-size: 1rem !important;
  line-height: 1.75 !important;
  background-color: $tertiary_btn-bg-color !important;
  color: $tertiary_btn-text-color !important;
}

.tertiary-button:hover {
  opacity: 0.85;
}

/* danger btn style  */

.danger-button {
  font-size: 1rem !important;
  line-height: 1.75 !important;
  background-color: $danger_btn-bg-color !important;
  color: $danger_btn-text-color !important;
  border: 1px solid $danger_btn-border-color !important;
}

.danger-button:hover {
  opacity: 0.85;
}

.MuiFormLabel-root.Mui-focused {
  color: $black-color !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $black-color !important;
}
.pull-right {
  float: right;
}
.center-align {
  justify-content: center;
}
// .text-center {
//   text-align: center;
// }
.text-end {
  text-align: end;
}
.text-wrap {
  word-break: break-word;
}
.text-bold {
  font-weight: bold !important;
}
.black-color {
  color: #000;
}
/* nft sorting modal  radio box style*/
.css-hyxlzm .MuiSvgIcon-root {
  color: $primary_btn-bg-color !important;
}

/*nft filter modal slider style*/
.MuiSlider-colorPrimary {
  color: $primary_btn-bg-color !important;
}

label {
  margin: 0;
}
.error {
  color: red;
}
.item-flex-end {
  display: flex;
  justify-content: flex-end;
}
.item-flex-center {
  display: flex;
  justify-content: center;
}
.infinite-scroll-end-message {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

button.modal-close-icon {
  color: #000;
}

.drawer-item-img {
  background-color: $primary_btn-bg-color;
  border-radius: 4px;
}

.MuiTabs-indicator {
  background-color: $primary_btn-bg-color !important;
}

//Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter
.Toastify__toast-body {
  color: $black-color;
}

.MuiSlider-valueLabel .MuiSlider-valueLabelLabel {
  color: $white-color !important;
}
.bold-divider {
  height: 1px;
  background-color: #000;
}

.loading-gif {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/**notification and chat  segment control styles **/
.segmented-control-chat {
  .controls {
    width: 100%;
  }
}
// notification radio btn styles
// .custom-control-label {
//   &::before {
//     border-color: $primary_btn-bg-color !important;
//     background-color: $primary_btn-bg-color !important ;
//     color: #000;
//   }
// }

.MuiFormHelperText-root {
  // margin-left: 0 !important;
}
