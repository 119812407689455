.header {
  position: relative;
  margin: 1rem 0 1rem 0;
  .drawer {
    position: absolute;
    left: 0;
    top: 0;
  }
  .main-title {
    text-align: center;
    h5 {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .lang-fav-wrapper {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    .fav-icon {
      width: 24px;
      margin-left: 0.7rem;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 510px) {
  .header {
    .main-title {
      padding-top: 2.5rem;
    }
  }
}
