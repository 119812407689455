.key-wrapper {
  width: 100%;
  word-wrap: break-word;
  .key-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    h5 {
      font-weight: bold;
    }
    .copy-content {
      color: #000 !important;
    }
  }
}
