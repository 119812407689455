.alertBox-wrapper {
  padding: 0.5rem 1rem !important;
  width: 525px;
  .alertBox-header {
    font-size: 1.3rem;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5rem !important;
    font-weight: bold;
    .confirm-alert-title {
      font-weight: bold;
    }
  }
  .alertBox-body {
    word-wrap: break-word;
    color: #000;
    padding: 0;
  }
  .alertBox-footer {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    button {
      color: $primary_btn-bg-color;
      font-weight: bold !important;
      border: 1px solid $primary_btn-bg-color !important;
      padding: 5px 25px;
      &:hover {
        color: $primary_btn-text-color !important;
        background: $primary_btn-bg-color !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .alertBox-wrapper {
    width: 280px;
    .alertBox-footer {
      flex-direction: column-reverse;
      button {
        margin: 0.7rem 0 0 0;
        width: 100%;
      }
    }
  }
}

.guest-user-alert-box {
  display: flex;
  flex-direction: column;
  button {
    color: black;
    &:hover {
      color: white;
      background: black;
    }
  }
  :nth-child(3) {
    color: red;
  }
}
