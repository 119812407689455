.auth-pages-wrapper {
  padding-right: 5rem;
  padding-left: 5rem;
  margin-right: auto;
  margin-left: auto;

  main {
    margin-bottom: 3rem;
  }

  & > hr {
    margin-top: 0;
  }
}

@media screen and (max-width: 991px) {
  .auth-pages-wrapper {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
