.keys-wrapper {
  width: 100%;
  margin-top: 1.5rem;
  word-break: break-word;
  .segment-control-wallet {
    .controls {
      width: 100%;
    }
  }
}
