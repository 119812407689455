.chat-container {
  // background-color: #f7f7f7;
  background-color: #fff;
  padding: 0.5rem !important;
}
.chat-header {
  display: flex;
  background-color: #fff;
  .back-arrow {
    margin-top: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .chat-profile {
    width: 100%;
    .rce-citem-body {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .rce-citem-body .rce-citem-body--top .rce-citem-body--top-time {
      display: none;
    }
    .rce-container-citem {
      .rce-citem {
        cursor: grab;
        .rce-citem-avatar {
          .rce-avatar-container.circle {
            border-radius: 15px !important;
            height: 60px;
            width: 60px;
          }
          .rce-avatar-container {
            img {
              border-radius: 15px !important;
            }
          }
        }
      }
      &:hover .rce-citem {
        background: #fff;
      }
    }
  }
}
.chat-body {
  padding: 1rem 0.5rem;
  height: 78vh;
  overflow: auto;
  .rce-container-mbox.message-box {
    .rce-mbox.rce-mbox-right {
      background-color: #0084ff;
      color: #fff;
      .rce-mbox-body {
        .rce-mbox-title {
          color: #fff;
          text-decoration: none;
          cursor: grab;
        }
        .rce-mbox-time::before {
          color: #fff;
        }
      }
      svg {
        fill: #0084ff !important;
      }
    }
  }
  .rce-container-mbox {
    .rce-mbox {
      background-color: #f7f7f7;
      .rce-mbox-body {
        .rce-mbox-title {
          color: #000;
          font-weight: bold;
          text-decoration: none;
          cursor: grab;
        }
      }
      svg {
        fill: #f7f7f7;
      }
    }
  }
}

.chat-input-area {
  textarea {
    border: 1px solid $primary_btn-bg-color;
    min-height: 48px !important;
  }
}
