.term-and-conds {
    word-break: break-word;
  }
  .term-and-conds a {
    color: black;
    text-decoration: none;
  }
  .term-and-conds a:hover {
    opacity: 0.5;
  }
  