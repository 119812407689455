.biddingItem {
  border: 1px solid;
  border-radius: 10px !important;
  margin-bottom: 1rem;
}
.biddingItemContent {
  display: flex;
  justify-content: space-between;
  .biddingInto {
    display: flex;
    flex-direction: column;
  }
}

.biddingItemFooter {
  display: flex;
  justify-content: center;
  background: rgb(181, 181, 181);
  button,
  button:disabled,
  button[disabled] {
    color: #fff;
    width: 100%;
    height: 100%;
    font-weight: bold;
  }
}
