.fourArt-guid {
  border: 1px solid #000;
  align-items: center;
  padding: 15;
}
.send-feedback {
  display: block;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
}
