.generic-modal-wrapper {
  width: 525px;
  .header {
    position: relative;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .sub-heading {
    text-align: center;
  }
  .body {
    word-wrap: break-word;
    color: #000;
  }

  .footer {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center !important;

    button {
      color: $primary_btn-bg-color;
      font-weight: bold !important;
      border: 1px solid $primary_btn-bg-color !important;
      padding: 5px 25px;
      &:hover {
        color: $primary_btn-text-color !important;
        background: $primary_btn-bg-color !important;
      }
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 786px) {
  .generic-modal-wrapper {
    width: 500px !important;
  }
}

@media screen and (max-width: 499px) {
  .generic-modal-wrapper {
    width: 310px !important;
  }
  .footer {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}
