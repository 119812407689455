.documents-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  .nft-documents-card {
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;
    text-align: -webkit-center;
    .nft-document-file img {
      width: 150px !important;
      height: 150px !important;
    }
    .nft-document-file .react-pdf__Document .react-pdf__Page .react-pdf__Page__textContent span {
      pointer-events: none !important;
    }
    .nft-document-name {
      text-align: center;
      font-weight: 700;
      margin-top: 10px;
    }
    .nft-document-date {
      text-align: center;
    }
  }
  .react-pdf__Page__canvas {
    width: 150px !important;
    height: 150px !important;
  }
}
