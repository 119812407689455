.react-confirm-alert-wrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  width: 500px;
  padding: 1.5rem 1rem !important;

  .confirm-alert-header {
    font-size: 1.3rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  .confirm-alert-body {
    padding: 1rem 0;
    word-wrap: break-word;
  }
  .confirm-alert-footer {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    button {
      color: $primary_btn-bg-color;
      font-weight: bold;
      border: 1px solid $primary_btn-bg-color !important;
      padding: 5px 25px;
      &:hover {
        color: $primary_btn-text-color !important;
        background: $primary_btn-bg-color !important;
      }
    }
    button:nth-of-type(2) {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .react-confirm-alert-wrapper {
    width: 295px !important;
    .confirm-alert-footer {
      flex-direction: column-reverse;
      button {
        margin: 0.7rem 0 0 0 !important;
        width: 100%;
      }
    }
  }
}
