.notification-item {
  .MuiListItemAvatar-root {
    img {
      margin-top: 0.5rem;
    }
  }
  .MuiListItemText-root {
    span {
      margin: 0.5rem 0 0 0.5rem;
    }
  }
  .notification-item-btn {
    font-size: 0.9rem !important;
    line-height: 1.75 !important;
    background-color: $seconday_btn-bg-color !important;
    color: $secondary_btn-text-color !important;
    border: 1px solid $secondary_btn-border-color !important;
    border-radius: 5px !important;
    margin: 0.5rem 0 0 0.5rem;
    padding: 0.3rem;
  }
  .notification-time {
    color: rgb(181, 181, 181);
    min-width: 120px;
    text-align: right;
  }
  div:nth-child(3) {
    top: 15px;
  }
}
